import { Buffer } from 'buffer';


export async function insertText(texte,context,position="End",style='') {
    console.log(context,texte);
    if(context=="Excel") {
        await Excel.run(async (context) => {
            let range = context.workbook.getSelectedRange();
            range.values = [[texte]];
            await context.sync();
    
        }).catch(function (error) {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }
    if(context=="Word") {
        await Word.run(async (context) => {
            const range = context.document.getSelection();
            
            const textInsert=range.insertText(texte, position);
            if (style!="") textInsert.style=style
            await context.sync();
    
        }).catch(function (error) {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }

}

export async function insertImage(dataUrl, fichier, AvecNumerotation = false, AvecAnnotation = false) {
    await Word.run(async (context) => {
        const range = context.document.getSelection();

        console.log(fichier);
        range.insertInlinePictureFromBase64(dataUrl.split(',')[1], "Start");
        if (fichier) {
            let text='';
            if (AvecNumerotation) {
                text = "Photo n°" + fichier.fiPhotoNumero;
            }
            if (AvecAnnotation) {
                text += (fichier.fiNotes != '' ? " : " + fichier.fiNotes : '');
            }
            if(text!='') {
                range.insertText(text, "End");
                range.paragraphs.getLast().alignment = "Centered";
            }
            
        }

        await context.sync();
    }).catch(function (error) {
        console.log('Error: ' + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
            console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        }
    });
}

export async function insertDocx(dataUrl) {
    await Word.run(async (context) => {
        const range = context.document.getSelection();
       

        range.insertFileFromBase64(dataUrl.split(',')[1], "Start");
      

        await context.sync();
    }).catch(function (error) {
        console.log('Error: ' + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
            console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        }
    });
}


function getImageDimensions(file) {
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  }

export async function insertionTableauImage(tblLocal, NbColonne = 1, AvecNumerotation = false, AvecAnnotation = false) {
    await Word.run(async (context) => {
        const range = context.document.getSelection();
        let i = 0;
        if (NbColonne > 1) {
            let table = range.insertTable(Math.ceil(tblLocal.length / NbColonne), NbColonne, Word.InsertLocation.before);
            table.load()
            table.autoFitWindow();
            await context.sync();
            
            while (i < tblLocal.length) {

                let cell = table.getCell(Math.floor(i / NbColonne), i % NbColonne).body;
               
                //table.getCell(Math.floor(i / NbColonne), i % NbColonne).columnWidth= 450 / NbColonne;
                 let img = cell.insertInlinePictureFromBase64(tblLocal[i].fiContenu.split(',')[1], "Start")
                img.load();
             
                var dimensions = await getImageDimensions(tblLocal[i].fiContenu)

               
                img.width = table.width/NbColonne;
                img.height=dimensions.h*( table.width/NbColonne)/dimensions.w
               // console.log(450 / NbColonne,dimensions.h*(450 / NbColonne)/dimensions.w)
                let text = ""
                if (AvecNumerotation) {
                    text = "Photo n°" + tblLocal[i].fiPhotoNumero;
                    if (AvecAnnotation) {
                        text += (tblLocal[i].fiNotes != '' ? " : " + tblLocal[i].fiNotes : '');
                    }
                } else {
                    if (AvecAnnotation) {
                        text = tblLocal[i].fiNotes;
                    }
                }
                cell.paragraphs.getLast().alignment = "Centered";
                cell.insertText("\r\n"+text, "End");
                //cell.insertText(text, "End");
                cell.paragraphs.getLast().alignment = "Centered";
               
                i++;
            }
        } else {
            while (i < tblLocal.length) {

                range.insertInlinePictureFromBase64(tblLocal[i].fiContenu.split(',')[1], "End");
                let text = ""
                if (AvecNumerotation) {
                    text = "Photo n°" + tblLocal[i].fiPhotoNumero;
                    if (AvecAnnotation) {
                        text += (tblLocal[i].fiNotes != '' ? " : " + tblLocal[i].fiNotes : '');
                    }
                } else {
                    if (AvecAnnotation) {
                        text = tblLocal[i].fiNotes;
                    }
                }

                range.paragraphs.getLast().alignment = "Centered";
                range.insertText("\r\n"+text+"\r\n"+"\r\n", "End");
                range.paragraphs.getLast().alignment = "Centered";
                i++;
            }
        }
        await context.sync();
        
    })
        .catch(function (error) {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
           
        });


}


export  async function insertHtml(html) {
    await Word.run(async (context) => {
        const range = context.document.getSelection();

        range.insertHtml(html)

        await context.sync();
    }).catch(function (error) {
        console.log('Error: ' + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
            console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        }
    });
}


