import { App } from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
/* global document, Office, module, require */
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './taskpane.css'

initializeIcons();

let isOfficeInitialized = false;
let logiciel = '';
//OfficeExtension.config.extendedErrorLogging = true;


const title = "GEX Online AddIn";

/*
document.oncontextmenu = function() {
    return false;
  }
  */
const render = (Component) => {
    ReactDOM.render(
        <AppContainer>
            <Component title={title} isOfficeInitialized={isOfficeInitialized} logiciel={logiciel} />
        </AppContainer>,
        document.getElementById("container")
    );
};


 

Office.onReady((context) => {
    logiciel = context.host
    isOfficeInitialized = true;
  
    render(App);


});


/* Render application after Office initializes */

/* Initial render showing a progress bar */
//render(App);

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(NextApp);
    });
}
